<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="linkExistingProductModal"
    :title="$t('Link to Existing product')"
    :buttons-hidden="true" autocomplete="nofill">
    <vx-card class="mt-4">
      <shipblu-v-select :class="linkedProduct ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
        autocomplete="nofill" label="name" optionalLabel="optionalLabel" name="product" v-model="linkedProduct" v-validate="'required'" 
        :placeholder="$t('Product') + '*'" :options="productCatalog" @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search/>
      <span class="text-danger text-sm" v-show="errors.has('product')">{{ errors.first('product') }}</span>
    </vx-card>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button class="active-btn btn" @click="linkProduct()">{{ $t('Link Product') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import common from '../../../../assets/utils/common'
import ShipbluVSelect from 'shipblu-vue-select'

export default {
  props: ['linkExistingProductModal', 'product'],
  data () {
    return {
      productCatalog: [],
      allProductsList: [],
      productFlags: [],
      fulfillmentCenters: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      merchantID: '',
      linkedProduct: ''
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  },
  watch: {
    'linkExistingProductModal' (val) {
      if (val === true) {
        this.loadProductFlags()
        this.loadFulfillmentCenters()
        this.loadProductCatalog()
        const merchantData = common.checkMerchantInLocalStorage(this)
        let user = {}
        merchantData.then(results => {
          user = results.userMerchantData
          this.merchantID = user.merchant
        })
      }
    }
  },
  methods: {
    closeModal () {
      this.linkedProduct = ''
      this.$validator.pause()
      this.$emit('loadStockProducts')
      this.$emit('linkExistingProductModal', false)
    },
    handleProductSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductsSearch, 1000)
    },
    loadProductsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadProductCatalog()
      }
    },
    loadProductCatalog () {
      this.searchInProgress = true
      sendFulfillmentRequest(false, false, this, `api/v1/fc/products/?merchant=${this.merchantID}&limit=100&search=${this.searchVal}`, 'get', null, true,
        (response) => {
          this.productCatalog = response.data.results
          this.productCatalog.map(item => { item.optionalLabel = `SKU: ${item.sku} . ${item.current_inventory_level} available` })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    loadProductFlags () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    linkProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            sku: this.linkedProduct.sku,
            product: this.linkedProduct.id,
            status: 'resolved'
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/products/${this.product.id}/`, 'patch', obj, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    }
  }
}
</script>