<template>
  <shipblu-prompt
    class="shipment-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="stockModal"
    :title="$t(type)"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="items-end grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
      <div class="col-span-1">
        <v-select :class="stockRequest.fulfillment_center ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="name" name="fulfillment center" v-model="stockRequest.fulfillment_center" v-validate="'required'"
          :placeholder="$t('Fulfillment Center') + '*'" :options="fulfillmentCenters"/>
        <span class="text-danger text-sm" v-show="errors.has('fulfillment center')">{{ errors.first('fulfillment center') }}</span>
      </div>
      <div class="col-span-1">
        <vs-input type="text" v-validate="'required|min_value:1'" :label-placeholder="$t('Number Of Vehicles') + '*'" name="number of vehicles" v-model="stockRequest.num_vehicles" :class="stockRequest.num_vehicles ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('number of vehicles')">{{ errors.first('number of vehicles') }}</span>
      </div>
    </div>
    <div v-for="(item, index) in stockProducts" :key="index">
      <div class="items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 mt-6">
        <div class="sm:col-span-2 col-span-1">
          <shipblu-v-select :disabled="$route.name === 'merchant-fulfillment-product-catalog'" :class="item.product ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
            autocomplete="nofill"  @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search
            label="sku" optionalLabel="optionalLabel" :name="`product-${index}`" v-model="item.product" v-validate="'required'" 
            :placeholder="$t('Product') + '*'" :options="productCatalog"/>
          <span class="text-danger text-sm" v-show="errors.has(`product-${index}`)">{{ errors.first(`product-${index}`) ? errors.first(`product-${index}`).split(`-${index}`)[0] + errors.first(`product-${index}`).split(`-${index}`)[1]  : '' }}</span>
        </div>
        <div class="sm:col-span-3 col-span-1">
          <v-select :class="item.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
            multiple autocomplete="nofill" label="name" :name="`flags-${index}`" v-model="item.flags" :placeholder="$t('Flags')" :options="productFlags"/>
        </div>
        <div class="md:col-span-2 col-span-5 flex items-end w-full justify-between">
          <div class="md:block flex items-center">
            <p class="text-sm text-lightgray mr-1">{{ $t('Incoming Quantity') }}:</p>
            <div>
              <vs-input-number min="1" v-validate="'required|numeric'" name="quantity" v-model="item.incoming_quantity" :value="0" class="stock-request-input-number flex justify-start"/>
              <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
            </div>
          </div>
          <div v-if="stockProducts.length > 1" @click="removeProduct(index)" class="remove-package-icon cursor-pointer mb-1">
            <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.name !== 'merchant-fulfillment-product-catalog'" class="relative flex items-center justify-end mt-8">
      <div class="absolute add-products">
        <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more products')}}</span>
      </div>
      <div class="p-1 flex self-center bg-primary rounded">
        <feather-icon @click="addProduct()" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button v-if="type === 'New Stock Request'" @click="addNewStockRequest" class="active-btn btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import i18nData from '../../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common from '../../../../assets/utils/common'
import ShipbluVSelect from 'shipblu-vue-select'

export default {
  props: ['stockModal', 'type', 'stockRequest', 'UROProducts', 'selected'],
  data () {
    return {
      stockProducts: [
        {
          product: '',
          flags: [],
          incoming_quantity: 1
        }
      ],
      merchantID: '',
      allProductsList: [],
      productCatalog: [],
      productFlags: [
        {
          id: 2,
          name: 'QC'
        },
        {
          id: 4,
          name: 'Fragile'
        }
      ],
      fulfillmentCenters: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  },
  watch: {
    'stockProducts': {
      handler (val) {
        const choosedProducts = []
        this.productCatalog = []
        this.allProductsList.forEach(element => {
          this.productCatalog.push(element)
        })
        val.forEach(item => {
          if (item.product && item.product !== null) {
            choosedProducts.push(item.product)
          }
        })
        for (let i = 0; i < choosedProducts.length; i++) {
          this.productCatalog.forEach(element => {
            if (choosedProducts[i] && (element.id === choosedProducts[i].id)) {
              this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
            }
          })
        }
      },
      deep: true
    },
    'searchVal' (val) {
      if (val === '') {
        this.loadProductCatalog()
      }
    },
    stockModal (val) {
      if (val === true) {
        this.stockRequest.fulfillment_center = {
          address: 63,
          code: 'CAI-DW',
          id: 1,
          name: 'Greater Cairo FC'
        } 
        this.loadFulfillmentCenters()
        this.loadProductCatalog()
      }
      if (val === true && this.selected) {
        this.stockProducts = []
        this.selected.forEach(item => {
          item.optionalLabel = `Name: ${item.name} . ${item.current_inventory_level} available`
          this.stockProducts.push({
            product: item, 
            flags: item.flags,
            incoming_quantity: 1
          })
        })
      }
      if (val === true && this.$route.name === 'merchant-unidentified-stock-request-view') {
        this.stockProducts = []
        this.UROProducts.forEach(item => {
          item.product.optionalLabel = `Name: ${item.name} . ${item.current_inventory_level} available`
          this.stockProducts.push({
            product: item.product,
            flags: item.product.flags,
            incoming_quantity: item.incoming_quantity
          })  
        })
      }
    }
  },
  methods: {
    handleProductSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductsSearch, 1000)
    },
    loadProductsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadProductCatalog()
      }
    },
    closeModal () {
      this.searchVal = ''
      this.$emit('selected', [])
      this.$emit('stockModal', false)
      this.$emit('loadStockRequests')
      this.stockProducts = [
        {
          product: '',
          flags: [],
          incoming_quantity: 1
        }
      ]
    },
    addProduct () {
      this.stockProducts.push({
        product: '',
        flags: [],
        incoming_quantity: 1
      })
    },
    removeProduct (index) {
      this.stockProducts.splice(index, 1)
    },
    loadProductCatalog () {
      this.searchInProgress = true
      sendFulfillmentRequest(false, false, this, `api/v1/fc/products/?merchant=${this.merchantID}&limit=100&search=${this.searchVal}`, 'get', null, true,
        (response) => {
          this.allProductsList = response.data.results
          this.productCatalog = []
          this.allProductsList.forEach(element => {
            this.productCatalog.push(element)
          })
          for (let i = 0; i < this.stockProducts.length; i++) {
            this.productCatalog.forEach(element => {
              if (element.id === this.stockProducts[i].product.id) {
                this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
              }
            })
          }
          this.allProductsList.map(item => { 
            item.optionalLabel = `Name: ${item.name} . ${item.current_inventory_level} available`
          })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    addNewStockRequest () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const stockRequestData = {
            merchant: this.merchantID,
            fulfillment_center: this.stockRequest.fulfillment_center.id,
            num_vehicles: Number(this.stockRequest.num_vehicles)
          }
          stockRequestData.products = []
          if (this.$route.name === 'merchant-unidentified-stock-request-view') {
            stockRequestData.unidentified_stock_request = Number(this.$route.params.stockID)
          }
          this.stockProducts.forEach(element => {
            stockRequestData.products.push({
              flags: element.flags.map(item => item.id),
              product: element.product.id,
              incoming_quantity: Number(element.incoming_quantity)
            })
          })
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/stock-requests/', 'post', stockRequestData, true,
            () => {
              this.closeModal()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      }) 
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantID = user.id
    })
  }
}
</script>

<style lang="scss">
.add-products {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #1C5BFE transparent transparent;
    }
  }
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
div.stock-request {
  .vs-input-number {
    * {
      padding: 0px !important;
    }
  }
  .vs-input-number--input {
    font-size: 15px !important;
  }
  .vs-input-number {
    margin: 0px 10px!important;
  }
  .vs-dialog{
    max-width: 800px !important;
  }
}
div.stock-request-input-number {
  button {
    width: 15px !important;
    height: 15px !important;
    
  }
}
</style>