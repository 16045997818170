<template>
  <shipblu-prompt
    class="order-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="stockProductModal"
    :title="$t(type) + ' ' + $t('Product')"
    :buttons-hidden="true" autocomplete="nofill">
    <vx-card v-for="(item, index) in unidentifiedProducts" :key="index" class="mt-4">
      <div class="items-end grid md:grid-cols-3 grid-cols-2 gap-x-4">
        <div v-if="item.type === 'unidentified'" class="md:col-span-1 col-span-2 md:mt-0 mt-4">
          <vs-input v-validate="'required'" :label-placeholder="$t('SKU Code') + '*'" :name="`sku code-${index}`" v-model="item.sku" :class="item.sku ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has(`sku code-${index}`)">{{ errors.first(`sku code-${index}`) ? errors.first(`sku code-${index}`).split(`-${index}`)[0] + errors.first(`sku code-${index}`).split(`-${index}`)[1]  : '' }}</span>
        </div>
        <div v-else class="md:col-span-1 col-span-2 md:mt-0 mt-4">
          <shipblu-v-select optionalLabel="optionalLabel" :class="item.product ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
            autocomplete="nofill" label="sku" :name="`product-${index}`" v-model="item.product" v-validate="'required'" 
            :placeholder="$t('Product') + '*'" :options="productCatalog" @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search/>
          <span class="text-danger text-sm" v-show="errors.has(`product-${index}`)">{{ errors.first(`product-${index}`) ? errors.first(`product-${index}`).split(`-${index}`)[0] + errors.first(`product-${index}`).split(`-${index}`)[1]  : '' }}</span>
        </div>
        <div class="md:col-span-1 col-span-2 md:mt-0 mt-4">
          <v-select :class="item.reason ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
            autocomplete="nofill" label="name" :name="`reason-${index}`" v-model="item.reason" v-validate="'required'" 
            :placeholder="$t('Reason') + '*'" :options="item.type === 'unidentified' ? unidentifiedReasones : identifiedReasones" />
          <span class="text-danger text-sm" v-show="errors.has(`reason-${index}`)">{{ errors.first(`reason-${index}`) ? errors.first(`reason-${index}`).split(`-${index}`)[0] + errors.first(`reason-${index}`).split(`-${index}`)[1]  : '' }}</span>
        </div>
        <div class="md:col-span-1 col-span-3 md:mt-0 mt-4">
          <div class="md:block flex items-center">
            <p class="text-sm text-lightgray mr-1">{{ $t('Incoming Quantity') }}:</p>
            <div>
              <vs-input-number min="1" :max="item.incoming_quantity" v-validate="'required|numeric'" name="quantity" v-model.number="item.quantity" :value="0" class="stock-request-input-number flex justify-start"/>
              <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
            </div>
          </div>
        </div>
        <div class="col-span-3">
          <vs-input :label-placeholder="$t('Product Description')" v-model="item.description" :class="item.description ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        </div>
      </div>
      <div v-if="type !== 'Edit'" @click="removeProduct(index)" class="remove-package-icon remove-icon-position cursor-pointer mb-1">
        <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
      </div>
    </vx-card>
    <template v-if="type === 'Add' && $route.name !== 'fc-operations-supervisor-fulfillment-stock-requests'">
      <div class="relative flex items-center justify-end" :class="unidentifiedProducts.length === 0 ? 'mt-0' : 'mt-8'">
        <div class="absolute add-products">
          <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-danger">{{$t('Add unidentified products')}}</span>
        </div>
        <div class="p-1 flex self-center bg-primary rounded">
          <feather-icon @click="addProduct('unidentified')" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
        </div>
      </div>
      <div class="relative flex items-center justify-end mt-4">
        <div class="absolute add-products">
          <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add identified products')}}</span>
        </div>
        <div class="p-1 flex self-center bg-primary rounded">
          <feather-icon @click="addProduct('identified')" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
        </div>
      </div>
    </template>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="updateProduct" v-if="type === 'Edit'" class="active-btn btn">{{ $t('Save') }}</button>
      <button v-else :disabled="unidentifiedProducts.length === 0" :class="unidentifiedProducts.length === 0 ? 'disable-btn' : 'active-btn'" class="btn" @click="addProducts">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import ShipbluVSelect from 'shipblu-vue-select'

export default {
  props: ['stockProductModal', 'type', 'stockProduct', 'UROstock', 'reasonsDic'],
  data () {
    return {
      merchants: [],
      productFlags: [],
      productCatalog: [],
      unidentifiedProducts: [
        {
          sku: null,
          description: null,
          incoming_quantity: 1,
          reason: null
        }
      ],
      types: [
        {
          name: 'Merchant',
          value: 'merchant',
          checked: false
        },
        {
          name: 'Stock Request',
          value: 'stock-request',
          checked: false
        },
        {
          name: 'Other',
          value: 'other',
          checked: true
        }
      ],
      unidentifiedReasones: [
        {
          name: 'Product is not found in your catalog',
          value: 'product_not_found'
        }
      ],
      identifiedReasones: [
        {
          name: 'Product description is not matched',
          value: 'product_not_matched'
        },
        {
          name: 'Product was not added to the WRO',
          value: 'product_not_added_to_wro'
        }
      ],
      clicks: 0,
      selectedType: 'other',
      merchant: '',
      searchVal: '',
      stockRequests: [],
      stockRequest: '',
      searchProductVal: ''
    }
  },
  watch: {
    stockProductModal (val) {
      if (val && this.type === 'Edit') {
        this.unidentifiedProducts = []
        this.stockProduct.type = this.stockProduct.product ? 'identified' : 'unidentified'
        this.stockProduct.quantity = this.stockProduct.incoming_quantity
        this.stockProduct.reason = {
          name: this.reasonsDic[this.stockProduct.reason],
          value: this.stockProduct.reason
        }
        if (this.stockProduct.product) {
          this.stockProduct.product.optionalLabel = `SKU: ${this.stockProduct.product.sku} . ${this.stockProduct.product.current_inventory_level} available`
        }
        this.unidentifiedProducts.push(this.stockProduct)
      } else {
        this.unidentifiedProducts = []
      }
    },
    UROstock (val) {
      if (val && val.stock_request) {
        this.selectedType = 'stock-request'
      } else if (val && val.merchant) {
        this.selectedType = 'merchant'
      }
      this.loadProductCatalog()
    }
  },
  methods: {
    loadProductCatalog () {
      const query = this.selectedType === 'merchant' ? `?merchant=${this.UROstock.merchant.id}&limit=100&search=${this.searchProductVal}` :  this.selectedType === 'stock-request' ? `?merchant=${this.UROstock.stock_request.merchant}&limit=100&search=${this.searchProductVal}` : `?limit=100&search=${this.searchProductVal}`
      this.searchInProgress = true
      sendFulfillmentRequest(false, false, this, `api/v1/fc/products/${query}`, 'get', null, true,
        (response) => {
          this.productCatalog = response.data.results
          this.productCatalog.map(item => { item.optionalLabel = `SKU: ${item.sku} . ${item.current_inventory_level} available` })
          this.searchedProductValue = this.searchProductVal
        }
      )
      this.searchInProgress = false
    },
    removeProduct (index) {
      this.unidentifiedProducts.splice(index, 1)
    },
    addProduct (productType) {
      this.unidentifiedProducts.push({
        sku: '',
        description: '',
        quantity: 1,
        product: '',
        reason: '',
        type: productType
      })
    },
    handleProductSearch () {},
    closeModal () {
      this.clicks = 0
      this.selectedType = 'other'
      this.merchant = ''
      this.searchVal = ''
      this.stockRequest = ''
      this.unidentifiedProducts = []
      this.$emit('loadStockProducts')
      this.$emit('stockProductModal', false)
    },
    checkedItem (type) {
      this.types.forEach(item => {
        if (item.name === type.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    addProducts () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result) {
          const products = []
          this.unidentifiedProducts.forEach(item => {
            products.push({
              sku: item.type === 'unidentified' ? item.sku : item.product.sku,
              product: item.type === 'unidentified' ? null : item.product.id,
              description: item.description,
              reason: item.reason.value,
              incoming_quantity: item.quantity
            })
          })
          sendFulfillmentRequest(false, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/products/`, 'post', products, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    },
    updateProduct () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result) {
          let product = {}
          product = {
            sku: this.unidentifiedProducts[0].type === 'unidentified' ? this.unidentifiedProducts[0].sku : this.unidentifiedProducts[0].product.sku,
            product: this.unidentifiedProducts[0].type === 'unidentified' ? null : this.unidentifiedProducts[0].product.id,
            reason: this.unidentifiedProducts[0].reason.value,
            description: this.unidentifiedProducts[0].description,
            incoming_quantity: this.unidentifiedProducts[0].quantity
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/unidentified-stock-requests/${this.$route.params.stockID}/products/${this.stockProduct.id}/`, 'patch', product, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  }
}
</script>

<style lang="scss">
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
</style>